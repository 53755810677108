"use strict";

var _interopRequireDefault = require("D:/git-aliyun/cloud-ui/ui-cp/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = _interopRequireDefault(require("highlight.js/lib/core"));

var _java = _interopRequireDefault(require("highlight.js/lib/languages/java"));

var _php = _interopRequireDefault(require("highlight.js/lib/languages/php"));

var _python = _interopRequireDefault(require("highlight.js/lib/languages/python"));

var _csharp = _interopRequireDefault(require("highlight.js/lib/languages/csharp"));

require("highlight.js/styles/xcode.css");

// highlight.js  代码高亮指令
//import Hljs from 'highlight.js';
_core.default.registerLanguage('java', _java.default);

_core.default.registerLanguage('php', _php.default);

_core.default.registerLanguage('python', _python.default);

_core.default.registerLanguage('csharp', _csharp.default);

// 代码高亮风格，选择更多风格需导入 node_modules/hightlight.js/styles/ 目录下其它css文件
var Highlight = {}; // 自定义插件

Highlight.install = function (Vue) {
  // 自定义指令 v-highlight
  Vue.directive('highlight', {
    // 被绑定元素插入父节点时调用
    inserted: function inserted(el) {
      var blocks = el.querySelectorAll('pre code');

      for (var i = 0; i < blocks.length; i++) {
        Hljs.highlightBlock(blocks[i]);
      }
    },
    // 指令所在组件的 VNode 及其子 VNode 全部更新后调用
    componentUpdated: function componentUpdated(el) {
      var blocks = el.querySelectorAll('pre code');

      for (var i = 0; i < blocks.length; i++) {
        Hljs.highlightBlock(blocks[i]);
      }
    }
  });
};

var _default = Highlight;
exports.default = _default;