exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#333",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#333",
	"menuBg": "#fff",
	"menuHover": "#509FFC",
	"subMenuBg": "#333",
	"subMenuHover": "#509FFC",
	"sideBarWidth": "200px"
};