import axios from 'axios';
import Message from 'element-ui/packages/message';
import MessageBox from 'element-ui/packages/message-box';
import store from '@/store';
import { getToken } from '@/utils/auth'; // 创建一个axios实例

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // url = base url + request url
  withCredentials: true,
  // 跨域请求时发送Cookie
  timeout: 15000 // 请求超时

}); // 请求拦截器

service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (store.getters.token) {
    // 让每个请求都带有令牌
    // ['X-Token'] 是自定义标题键
    // 请根据实际情况进行修改
    config.headers['Authorization'] = getToken();
  }

  return config;
}, function (error) {
  // 做一些请求错误
  console.log(error); // for debug

  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(
/**
  *通过自定义代码确定请求状态
  *这只是一个例子
  *您也可以通过HTTP状态代码来判断状态
  */
function (response) {
  var res = response.data; // 如果自定义代码不是20000，则将其判断为错误。

  if (res.code !== 0 && res.code !== 1 && res.code !== 10001) {
    var resPath = window.location.pathname;
    var resLogout = false; // 401代表授权信息过期或非法

    if (res.code === 401) {
      if (resPath === '/login' || resPath === '/findpwd' || resPath === '/register') {
        // 以上地址不提示授权失败
        resLogout = false;
      } else {
        resLogout = true;
      }
    }

    if (resLogout === true) {
      // 重新登录
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      });
      localStorage.setItem('Authorization', '');
      localStorage.setItem('refreshToken', '');
      setTimeout(function () {
        top.location.href = '/login';
      }, 800); // MessageBox.confirm('您已注销或超时，请再次登录', '确认登出', {
      //  confirmButtonText: '确定',
      //  type: 'warning',
      //  showCancelButton: false,
      //  center: true
      // }).then(() => {
      //  top.location.href = '/login'
      // store.dispatch('user/resetToken').then(() => {
      //  location.reload()
      // })
      // })
    } else {
      if (res.code === 405) {
        MessageBox(res.msg, '提示', {
          confirmButtonText: '前往开发者中心',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(function () {
          window.location.href = process.env.VUE_APP_DEV_URL;
        }).catch(function () {});
      } else {
        if (res.msg != null) {
          Message({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
          });
        }
      }
    }

    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug

  Message({
    message: '服务异常或请求超时',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;