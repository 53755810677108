import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "D:\\git-aliyun\\cloud-ui\\ui-cp\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\git-aliyun\\cloud-ui\\ui-cp\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\git-aliyun\\cloud-ui\\ui-cp\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\git-aliyun\\cloud-ui\\ui-cp\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from "./utils/element"; //import Element from 'element-ui'

import "./styles/element-variables.scss";
import '@/styles/animate.min.css';
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization

import "./icons"; // icon

import "./permission"; // permission control
//import './utils/error-log' // error log

import * as filters from "./filters"; // global filters
// highlight.js代码高亮插件

import Highlight from "./utils/highlight"; // from 路径是highlight.js的路径，纯属自定义

import '@/assets/font/font.css';
Vue.use(Highlight);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
//import { mockXHR } from '../mock'
//if (process.env.NODE_ENV === 'development') {
//  mockXHR()
//}

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
}); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
import moment from 'moment';
Vue.filter('dateFormat', function (input, fmtstring) {
  //当input为时间戳，需转为Number类型
  if (input) {
    return moment(input).format(fmtstring);
  } else {
    return "";
  }
});
Vue.directive('debounce', {
  inserted: function inserted(el, binding) {
    el.addEventListener('click', function (e) {
      el.classList.add('is-disabled');
      el.disabled = true;
      setTimeout(function () {
        el.disabled = false;
        el.classList.remove('is-disabled');
      }, 1000); // 1秒
    });
  }
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});