import request from '@/utils/request';
import qs from 'qs';
export function register(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/register',
    method: 'post',
    data: qs.stringify(data)
  });
} // 账号密码登录

export function loginPwd(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/entry',
    method: 'post',
    data: qs.stringify(data)
  });
} // 快速登录

export function phoneLogin(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/phoneLogin',
    method: 'post',
    data: qs.stringify(data)
  });
} // 会员信息

export function getInfo() {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/merch/getMerchInfo',
    method: 'POST'
  });
} // 人机验证

export function afsCheck(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/afsCheck',
    method: 'post',
    data: qs.stringify(data)
  });
}
export function resetToken() {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/resetToken',
    method: 'POST'
  });
}
export function modifyInfo(data) {
  return request({
    headers: {
      'content-type': 'multipart/form-data'
    },
    url: '/merch/updateMerchInfo',
    method: 'post',
    data: data
  });
} // 校验验证码

export function checkVerifyCode(query) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/checkVerifyCode',
    method: 'get',
    params: query
  });
} // 退出登录

export function logout() {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/exit',
    method: 'post'
  });
} //  修改密码

export function modifyPassword(data) {
  return request({
    url: '/merch/updateMerchPassword',
    method: 'post',
    data: qs.stringify(data)
  });
}
export function getVerifyCode(query) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/getVerifyCode',
    method: 'get',
    params: query
  });
} // 忘记密码

export function forgetPassword(query) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/forgetPassword',
    method: 'post',
    params: query
  });
} // 修改密码

export function changePassword(query) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/changePassword',
    method: 'post',
    params: query
  });
} // 验证用户名

export function getMerchByUsername(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/getMerchByUsername',
    method: 'post',
    data: qs.stringify(data)
  });
} // 获取验证码

export function getCodeImg() {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/imageCode',
    method: 'get'
  });
} // 授权后登陆

export function ossLogin(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/login/ossLogin',
    method: 'post',
    data: qs.stringify(data)
  });
} // 商户转开发者 提交信息

export function exchangeDev(data) {
  return request({
    headers: {
      'content-type': 'multipart/form-data'
    },
    url: '/developer/exchangeDev',
    method: 'post',
    data: data
  });
} // 解除微信绑定

export function unBindWx(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/merch/wxUnbind',
    method: 'post',
    data: qs.stringify(data)
  });
} // 是否绑定微信

export function isBindWx(data) {
  return request({
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    url: '/merch/wxIsBind',
    method: 'post',
    data: qs.stringify(data)
  });
}