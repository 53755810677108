"use strict";

var _interopRequireDefault = require("D:/git-aliyun/cloud-ui/ui-cp/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getLanguage = getLanguage;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("D:/git-aliyun/cloud-ui/ui-cp/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _en2 = _interopRequireDefault(require("./en"));

var _zh = _interopRequireDefault(require("./zh"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

//import esLocale from './es'
//import jaLocale from './ja'
_vue.default.use(_vueI18n.default);

var messages = {
  en: _objectSpread(_objectSpread({}, _en2.default), _en.default),
  zh: _objectSpread(_objectSpread({}, _zh.default), _zhCN.default) //es: {
  //  ...esLocale,
  //  ...elementEsLocale
  //},
  //ja: {
  //  ...jaLocale,
  //  ...elementJaLocale
  //}

};

function getLanguage() {
  var chooseLanguage = _jsCookie.default.get('language');

  if (chooseLanguage) return chooseLanguage; // if has not choose language

  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'en';
}

var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
var _default = i18n;
exports.default = _default;