import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout';
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/store/main',
  component: Layout,
  children: [{
    path: '/store',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'home',
      affix: true
    }
  }]
}, {
  path: '/',
  component: function component() {
    return import('@/views/isv/index');
  },
  hidden: true
}, {
  path: '/icons',
  component: function component() {
    return import('@/views/icons/index');
  },
  hidden: true
}, {
  path: '/isv',
  component: function component() {
    return import('@/views/isv/index');
  },
  hidden: true
}, {
  path: '/isv/app',
  component: function component() {
    return import('@/views/isv/app');
  },
  hidden: true
}, {
  path: '/isv/help',
  component: function component() {
    return import('@/views/isv/help');
  },
  hidden: true
}, {
  path: '/isv/video',
  component: function component() {
    return import('@/views/isv/video');
  },
  hidden: true
}, {
  path: '/isv/question',
  component: function component() {
    return import('@/views/isv/question');
  },
  hidden: true
}, {
  path: '/frontmanage/openoauth/authorize',
  component: function component() {
    return import('@/views/openoauth/bind');
  },
  hidden: true,
  meta: {
    title: '开发者授权 - 佳博云打印系统',
    noCache: true
  }
}, {
  path: '/frontmanage/openoauth/unauthorize',
  component: function component() {
    return import('@/views/openoauth/unbind');
  },
  hidden: true,
  meta: {
    title: '解除开发者授权 - 佳博云打印系统',
    noCache: true
  }
}, {
  path: '/app/zpltsc',
  component: function component() {
    return import('@/views/appzpltsc/index');
  },
  hidden: true
}, {
  path: '/app/zpltsc2',
  component: function component() {
    return import('@/views/appzpltsc2/index');
  },
  hidden: true
}, {
  path: '/app/esc',
  component: function component() {
    return import('@/views/appesc/index');
  },
  hidden: true
}, {
  path: '/app/escCodeEditor',
  component: function component() {
    return import('@/views/appesc/codeEditor');
  },
  hidden: true
}, {
  path: '/oss/request',
  component: function component() {
    return import('@/views/oss/ossLogin.vue');
  },
  hidden: true,
  meta: {
    title: '用户授权单点登录 - 佳博云打印系统'
  }
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [// 添加打印机
{
  path: '/device/add',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/deviceadd/index');
    },
    name: 'DeviceAdd',
    meta: {
      title: 'deviceAdd',
      icon: 'posAdd',
      noCache: true
    }
  }]
}, // 打印机管理列表
{
  path: '/device?',
  component: Layout,
  name: 'Device',
  meta: {
    title: 'device',
    icon: 'posPrinter'
  },
  children: [{
    path: '/device',
    component: function component() {
      return import('@/views/device/index');
    },
    name: 'DeviceList',
    meta: {
      title: 'deviceList',
      noCache: true
    }
  }, {
    path: '/device/location',
    component: function component() {
      return import('@/views/devicelocation/index');
    },
    name: 'DeviceLocation',
    hidden: true,
    meta: {
      title: 'deviceLocation',
      noCache: true
    }
  }, {
    path: '/order',
    component: function component() {
      return import('@/views/order/index');
    },
    name: 'OrderList',
    hidden: true,
    meta: {
      title: 'orderList',
      noCache: true
    }
  }, {
    path: '/device/add',
    component: function component() {
      return import('@/views/deviceadd/index');
    },
    hidden: true
  }, {
    path: '/device/control',
    component: function component() {
      return import('@/views/devicecontrol/index');
    },
    hidden: true
  }, {
    path: '/device/control/tcp',
    component: function component() {
      return import('@/views/devicecontrol/indexbytcp.vue');
    },
    hidden: true
  }, {
    path: '/device/listswitch',
    component: function component() {
      return import('@/views/deviceswitch/index');
    },
    hidden: true
  }, {
    path: '/device/switchguide',
    component: function component() {
      return import('@/views/deviceswitch/switchguide');
    },
    hidden: true
  }]
}, // 美团饿了么列表
{
  path: '/platshop?',
  component: Layout,
  name: 'Platshop',
  meta: {
    title: 'platshopBind',
    icon: 'posShop'
  },
  children: [{
    path: '/platshop/meituanhelp',
    component: function component() {
      return import('@/views/platshop/meituanhelp');
    },
    hidden: true
  }, {
    path: '/platshop/info',
    component: function component() {
      return import('@/views/platshop/info');
    },
    hidden: true
  }, {
    path: '/platshop/edit',
    component: function component() {
      return import('@/views/platshop/edit');
    },
    hidden: true
  }, {
    path: '/platshop',
    component: function component() {
      return import('@/views/platshoplist/index');
    },
    name: 'PlatshopList',
    meta: {
      title: 'platshopList',
      noCache: true
    }
  }, {
    path: '/platshop/order',
    component: function component() {
      return import('@/views/platshoporder/index');
    },
    name: 'OrderList',
    meta: {
      title: 'platOrderList',
      noCache: true
    }
  }]
}, // 第三方应用管理
{
  path: '/app/authorize?',
  component: Layout,
  name: 'application',
  meta: {
    title: 'application',
    icon: 'posApp'
  },
  children: [{
    path: '/app/authorize',
    component: function component() {
      return import('@/views/appauthorize/index');
    },
    name: 'AppAuthorize',
    meta: {
      title: 'appAuthorize',
      noCache: true
    }
  }, {
    path: '/app/templet',
    component: function component() {
      return import('@/views/templet/index');
    },
    name: 'AppTemplet',
    meta: {
      title: 'template',
      noCache: true
    }
  }, {
    path: '/app/esc',
    name: 'AppEsc',
    hidden: true
  }, {
    path: '/app/invoice',
    component: function component() {
      return import('@/views/appinvoice/index');
    },
    name: 'AppInvoice',
    meta: {
      title: 'appInvoice',
      noCache: true
    }
  }]
}, // 用户信息
{
  path: '/profile?',
  component: Layout,
  meta: {
    title: 'profile',
    icon: 'posUser'
  },
  children: [{
    path: '/profile/modifypwd',
    component: function component() {
      return import('@/views/modifypwd/index');
    },
    name: 'UserModifyPass',
    meta: {
      title: 'userModifyPass',
      noCache: true
    }
  }, {
    path: '/profile',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'UserInfo',
    meta: {
      title: 'userInfo',
      noCache: true
    }
  }, {
    path: '/profile/exchangeDev',
    component: function component() {
      return import('@/views/profile/exchangeDev');
    },
    name: 'exchangeDev',
    meta: {
      title: 'exchangeDev',
      noCache: true
    }
  }]
}, // 退出登录
{
  path: '/logout',
  component: Layout,
  children: [{
    path: '/logout/index',
    component: function component() {
      return import('@/views/logout/index');
    },
    name: 'Logout',
    meta: {
      title: 'logout',
      icon: 'posLogout',
      noCache: true
    }
  }]
}, {
  path: '*',
  redirect: '/',
  hidden: true
}
/*
  // 统计中心
  {
  path: '/order',
  component: Layout,
  meta: {
    title: 'orderlist',
    icon: 'statistics'
  },
  children: [
      {
    path: 'chart',
    component: () => import('@/views/order/chart'),
    name: 'Orderlist',
    meta: { title: 'latitude', noCache: true }
    },
    {
    path: 'personalise',
    component: () => import('@/views/order/index'),
    hidden: true,
    name: 'Orderlist',
    meta: { title: 'personalise', noCache: true }
    }
  ]
  },
  // 开发者中心
  {
  path: '/developer',
  component: Layout,
  meta: {
    title: 'developer',
    icon: 'developer'
  },
  children: [
    {
    path: 'index',
    component: () => import('@/views/develop/index'),
    meta: { title: 'developInfo', noCache: true }
    },
    {
    path: 'interface',
    component: () => import('@/views/develop/interface'),
    meta: { title: 'interfaceList', noCache: true }
    },
    {
    path: 'sdk',
    component: () => import('@/views/develop/sdk'),
    meta: { title: 'sdk', noCache: true }
    },
    {
    path: 'http://cloud.poscom.cn/demo/',
    meta: { title: 'onlineTest', noCache: true }
    }
  ]
  },
{
  path: '*',
  redirect: '/404',
  hidden: true
}
  */
];

var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // 需要服务支持
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;