import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
// 从element-ui导入想要使用的组件
import { Button, Radio, RadioGroup, RadioButton, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, Submenu, MenuItemGroup, MenuItem, Breadcrumb, BreadcrumbItem, Card, Row, Col, Table, TableColumn, Switch, Tooltip, Pagination, Dialog, MessageBox, Tag, Tree, Select, Option, Cascader, Alert, Tabs, TabPane, Step, Steps, Checkbox, CheckboxGroup, Upload, Slider, Scrollbar, Timeline, TimelineItem, Loading, InputNumber, Dropdown, DropdownItem, DropdownMenu, DatePicker, Popover, Descriptions, DescriptionsItem } from 'element-ui';
export default {
  install: function install(Vue, options) {
    var components = [Header];
    components.forEach(function (component) {
      Vue.component(component.name, component);
    });
    Vue.prototype.$ELEMENT = {
      size: options.size || '',
      zIndex: options.zIndex || 2000
    }; // 将弹框组件全局挂在到VUe原形实例

    Vue.use(Button);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Input);
    Vue.use(Container);
    Vue.use(Header);
    Vue.use(Aside);
    Vue.use(Main);
    Vue.use(Menu);
    Vue.use(Submenu);
    Vue.use(MenuItemGroup);
    Vue.use(MenuItem);
    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(Card);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Switch);
    Vue.use(Tooltip);
    Vue.use(Pagination);
    Vue.use(Dialog);
    Vue.use(Tag);
    Vue.use(Tree);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Cascader);
    Vue.use(Alert);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Step);
    Vue.use(Steps);
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    Vue.use(Slider);
    Vue.use(Scrollbar);
    Vue.use(Upload);
    Vue.use(Timeline);
    Vue.use(TimelineItem);
    Vue.use(Loading);
    Vue.use(InputNumber);
    Vue.use(Dropdown);
    Vue.use(DropdownItem);
    Vue.use(DropdownMenu);
    Vue.use(DatePicker);
    Vue.use(Popover);
    Vue.use(Descriptions);
    Vue.use(DescriptionsItem);
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;
  }
};