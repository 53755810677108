"use strict";

var _interopRequireDefault = require("D:/git-aliyun/cloud-ui/ui-cp/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

var _settings = _interopRequireDefault(require("@/settings"));

var _lang = _interopRequireDefault(require("@/lang"));

var title = _settings.default.title || 'Vue Element Admin';

function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));

  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));

    return "".concat(pageName, " - ").concat(title);
  }

  return "".concat(title);
}