"use strict";

var _interopRequireDefault = require("D:/git-aliyun/cloud-ui/ui-cp/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

var _document = document,
    body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design

var _default = {
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        _store.default.dispatch('app/closeSideBar', {
          withoutAnimation: false
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var isMobile = this.$_isMobile();
    /*
    //console.log('isMobile=>')
    if (isMobile) {
    	this.$nextTick(() => {
         this.$router.replace({
    path: '/redirect' + '/mobile/'
         })
       })
    }
    */

    window.addEventListener('resize', this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  mounted: function mounted() {
    /*
       const isMobile = this.$_isMobile()
       console.log('isMobile=>')
       if (isMobile) {
       	this.$nextTick(() => {
            this.$router.replace({
             //path: '/redirect' + this.$route.fullPath
           	path: '/redirect' + '/mobile/index'
            })
          })
         //store.dispatch('app/toggleDevice', 'mobile')
         //store.dispatch('app/closeSideBar', { withoutAnimation: true })
       }
       */
  },
  methods: {
    // use $_ for mixins properties
    $_isMobile: function $_isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.$_isMobile();

        _store.default.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop');

        if (isMobile) {
          _store.default.dispatch('app/closeSideBar', {
            withoutAnimation: true
          });
        }
      }
    }
  }
};
exports.default = _default;